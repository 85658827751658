
/**
 * 取目标层级的数据，如没有则主动创建一个空对象
 * @param {object} data 需要取值的数据
 * @param {String} keys  keys需要取的值
 * @param {any} value  可选 如有则赋值
 * 
 */
export const nestedValue = (data, keys, value) => {
  if (typeof keys === 'string') {
    keys = keys.split('.')
  }

  let current = data

  for (let i = 0; i < keys.length; i++) {
    let key = keys[i]

    // 最后一层直接返回
    if (i === keys.length - 1) {
      if (value !== undefined) {
        current[key] = value
      }

      current = current[key]
      break
    } else {
      // 非最后一层，如果当前层没有该key，则创建一个空对象
      if (!current[key]) {
        current[key] = {}
      }
      current = current[key]
    }
  }

  return current
}

export const getNestedValue = (data, keys) => {
  return nestedValue(data, keys)
}

export const setNestedValue = (data, keys, value) => {
  return nestedValue(data, keys, value)
}
